var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticStyle: { position: "absolute" } },
    [
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 0, color: _vm.item.color, top: "" },
          on: { click: _vm.next },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.item.message) + "\n    "),
          _vm.queues.length
            ? _c(
                "v-chip",
                {
                  staticClass: "ml-2 pa-0 px-2",
                  attrs: { disabled: "", small: "" }
                },
                [_vm._v(_vm._s(_vm.queues.length || "") + "+ more")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.next } },
            [_c("v-icon", [_vm._v("mdi-close-circle")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }