<template>
  <v-app light id="vue-app">
    <v-app-bar
      dark
      :class="scrollVisible ? 'primary' : 'transparent'"
      fixed
      :flat="!scrollVisible"
      dense
      style="transition : all .5s ease-in-out"
    >
      <v-toolbar-title>TeraWORK&trade;</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small dark outlined @click="showLogin = true">Sign in</v-btn>
    </v-app-bar>
    <v-content>
      <section>
        <v-parallax
          class="parallax-header"
          src="/img/landing/payroll-desk.png"
          height="600"
          :style="
                        `${
                            pricing ? 'height : 100vh' : ''
                        };background :linear-gradient(90deg, #f68946, #f69753)`
                    "
        >
          <v-layout column align-center justify-center class="white--text">
            <img :src="`/storage/logos/${hostName}.logo`" width="300" class="mb-4" />
            <h1 class="white--text mb-2 display-1 text-xs-center">{{ siteConfig.business_name }}</h1>
            <div class="subheading mb-3 text-xs-center">Powered by Teratomo</div>
            <v-btn large color="blue" dark @click="startOrLogin">Get Started</v-btn>
          </v-layout>
        </v-parallax>
      </section>
      <section>
        <v-container grid-list-xl>
          <v-layout row wrap justify-center class="my-5">
            <v-flex xs12 sm4>
              <v-card class="elevation-0 transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline">Company info</div>
                </v-card-title>
                <v-card-text>
                  {{
                  siteConfig.company_description
                  }}
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 sm4 offset-sm1>
              <v-card class="elevation-0 transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline">Contact Us</div>
                </v-card-title>
                <v-list class="transparent">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon class="blue--text text--lighten-2">phone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                        siteConfig.contact_no
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon class="blue--text text--lighten-2">place</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{
                      siteConfig.address
                      }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon class="blue--text text--lighten-2">email</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                        siteConfig.email
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </section>
      <v-footer class="primary">
        <v-layout row wrap align-center>
          <v-flex xs12>
            <div class="white--text ml-3">
              Made with
              <v-icon class="red--text">favorite</v-icon>by
              <a class="white--text" href="https://www.teratomo.com" target="_blank">Kaizen Team</a>
            </div>
          </v-flex>
        </v-layout>
      </v-footer>
    </v-content>
    <v-navigation-drawer temporary app right v-model="showLogin" width="500">
      <core-login-drawer v-model="showLogin" @close="showLogin =false" />
    </v-navigation-drawer>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    formData: {
      email: "",
      password: ""
    },
    showLogin: false,
    scrollVisible: false,
    pricing: false,
    siteConfig: {},
    page: 0
  }),
  computed: {
    hostName() {
      return window.location.hostname;
    }
  },
  async created() {
    window.onscroll = this.catchScroll;

    const { data } = await axios.get("site-info");

    this.siteConfig = data;
  },
  methods: {
    async startOrLogin() {
      console.log("startOrLogin");

      try {
        const { data: user } = await axios.get("/auth/me");
        window.location.href =
          user.preferred_landing || user.roles[0].landing_page;
      } catch (error) {
        console.error(error);

        this.showLogin = true;
      }
    },
    catchScroll() {
      const pastTopOffset = window.pageYOffset > parseInt(500);
      const pastBottomOffset =
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - parseInt(0);
      this.scrollVisible =
        parseInt(0) > 0 ? pastTopOffset && !pastBottomOffset : pastTopOffset;
      // this.scrollFn(this);
    }
  }
};
</script>

<style>
.parallax-header .v-parallax__image {
  opacity: 0.3 !important;
}
</style>
