import { render, staticRenderFns } from "./landing.vue?vue&type=template&id=3aa35c44&"
import script from "./landing.vue?vue&type=script&lang=js&"
export * from "./landing.vue?vue&type=script&lang=js&"
import style0 from "./landing.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import CoreLoginDrawer from '@/components/core/LoginDrawer.vue'
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {CoreLoginDrawer,VApp,VAppBar,VBtn,VCard,VCardText,VCardTitle,VContainer,VContent,VFlex,VFooter,VIcon,VLayout,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VNavigationDrawer,VParallax,VSpacer,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3aa35c44')) {
      api.createRecord('3aa35c44', component.options)
    } else {
      api.reload('3aa35c44', component.options)
    }
    module.hot.accept("./landing.vue?vue&type=template&id=3aa35c44&", function () {
      api.rerender('3aa35c44', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/pages/landing.vue"
export default component.exports