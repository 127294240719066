<template>
  <v-layout align-center justify-center fill-height class="white">
    <v-btn
      icon
      small
      text
      top
      left
      fixed
      color="primary"
      @click="
        tab = 0;
        $emit('close');
      "
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-flex xs9>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-form @submit.prevent="submit">
            <v-layout column justify-center>
              <v-flex>
                <v-layout row wrap justify-center py-2>
                  <span class="display-3">Sign In</span>
                </v-layout>
              </v-flex>
              <v-flex class="pb-3">
                <v-layout row wrap align-center justify-center>
                  <v-btn dark icon color="blue">
                    <v-icon>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn dark icon color="red">
                    <v-icon>mdi-gmail</v-icon>
                  </v-btn>
                </v-layout>
              </v-flex>
              <v-flex>
                <v-text-field
                  ref="email"
                  type="email"
                  v-model="formData.email"
                  v-validate="'required|email'"
                  data-vv-validate-on="blur"
                  :error-messages="errors.collect('Email')"
                  name="Email"
                  label="Email"
                  prepend-inner-icon="mdi-account"
                  @keypress.enter="submit"
                ></v-text-field>
                <v-text-field
                  type="password"
                  v-model="formData.password"
                  v-validate="'required'"
                  :error-messages="errors.collect('Password')"
                  name="Password"
                  label="Password"
                  prepend-inner-icon="mdi-shield-lock-outline"
                  @keypress.enter="submit"
                ></v-text-field>
              </v-flex>
              <v-flex class="layout justify-center mb-2">
                <v-btn text small @click="tab = 1">Forgot password?</v-btn>
              </v-flex>
              <v-flex class="layout justify-center">
                <v-btn rounded class="primary" block large @click="submit">Sign In</v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </v-tab-item>
        <v-tab-item>
          <v-form>
            <v-layout column>
              <v-flex>
                <v-layout row wrap justify-center py-2>
                  <span class="display-1">Forgot Password</span>
                </v-layout>
              </v-flex>
              <template v-if="emailSent">
                <v-flex>
                  <v-alert
                    type="success"
                    :value="emailSent"
                    dismissible
                  >If your email is registered in users list, you will receive an email for instructions on how to reset your password.</v-alert>
                </v-flex>
                <v-flex>
                  <div
                    class="d-flex justify-center caption grey--text"
                  >You will be redirected in a few second... {{redirectSeconds}}</div>
                  <v-btn @click="tab = 0" block color="primary" small rounded>Login</v-btn>
                </v-flex>
              </template>
              <template v-else>
                <v-flex>
                  <v-text-field
                    v-model="formData.email"
                    name="Email"
                    label="Email"
                    prepend-inner-icon="mdi-email"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 class="mb-3 align-center justify-center d-flex">
                  <vue-recaptcha
                    ref="recaptcha"
                    @verify="onVerify"
                    @expired="onExpired"
                    :sitekey="sitekey"
                  ></vue-recaptcha>
                </v-flex>
                <v-flex>
                  <v-layout>
                    <v-spacer></v-spacer>
                    <v-btn text @click="tab = 0">Cancel</v-btn>
                    <v-btn :disabled="!verified" color="primary" rounded @click="sendResetEmail">
                      <v-icon left>mdi-email</v-icon>Submit
                    </v-btn>
                  </v-layout>
                </v-flex>
              </template>
            </v-layout>
          </v-form>
        </v-tab-item>
      </v-tabs-items>
    </v-flex>
    <v-dialog v-model="loading" persistent max-width="300px" transition="dialog-transition">
      <v-card>
        <v-card-text class="pt-3">
          <v-layout column align-center justify-center>
            <v-progress-circular indeterminate color="primary"></v-progress-circular>Please wait...
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
export default {
  props: ["value"],
  components: { VueRecaptcha },
  data: () => ({
    tab: 0,
    emailSent: false,
    formData: {},
    loading: false,
    sitekey: "6LexUeYUAAAAADLAnZRtjdmWpiSzZj8c_knWxTRC",
    verified: false,
    redirectSeconds: 5,
    timeout: null
  }),
  methods: {
    onVerify() {
      this.verified = true;
    },
    onExpired() {
      this.$refs.recaptcha.reset();
    },
    async sendResetEmail() {
      if (this.verified) {
        try {
          this.loading = true;
          await axios.post("/password/reset", this.formData);
          this.emailSent = true;
          this.timeout = setInterval(() => {
            if (!--this.redirectSeconds) {
              clearInterval(this.timeout);
              this.errors.clear();
              this.tab = 0;
            }
          }, 1000);
        } catch (error) {
          console.log(error);
        }
        this.formData = {};
        this.verified = false;
        this.loading = false;
        this.$refs.recaptcha.reset();
      }
    },
    async submit() {
      const valid = await this.$validator.validateAll();
      if (valid) {
        this.loading = true;
        try {
          const { data } = await axios.post("/login", this.formData);

          this.$ls.set("apiToken", data.api_token);
          this.$ls.set("show-tab", true);

          if (data.is_admin && data.mode) {
            window.location.href =
              data.preferred_landing || data.roles[0].landing_page;
          } else {
            window.location.href = "/";
          }
        } catch (err) {
          this.$toast.error("Login failed");
          console.log(err);
        }
        this.loading = false;
      }
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.$refs.email.$el.querySelector("input").focus();
      } else {
        this.tab = 0;
      }
    }
  }
};
</script>
