import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/scss/materialdesignicons.scss";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdi"
    },

    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: "#f68946",
                secondary: "#f69753",
                lightblue: "#e0e6ff",
                dimgrey: "#d7d7d7",
                lightordark: "#fff",
                titlecolor: "#353535",
                lightgrey: "#c4c4c4"
            },
            dark: {
                primary: "#f68946",
                secondary: "#f69753",
                lightblue: "#585858",
                greyline: "#565656",
                dimgrey: "#696969",
                lightordark: "#424242",
                titlecolor: "#f8f8f8",
                lightgrey: "#868686"
            }
        }
    }
});
