import Vue from "vue";
import VueLocalStorage from "vue-ls";
import VeeValidate from "vee-validate";
import Vue2Filters from "vue2-filters";
import VueCookie from "vue-cookie";
import VueMoment from "vue-moment";

Vue.config.devtools = process.env.NODE_ENV === "development";

Vue.use(VueMoment);

Vue.use(Vue2Filters);
Vue.use(VeeValidate);
Vue.use(VueLocalStorage);
Vue.use(VueCookie);

console.log(process.env.MIX_APP_DATE_FORMAT);

window.GLOBAL = {
    timeRegex: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/,
    dateFormat: process.env.MIX_APP_DATE_FORMAT
};
Vue.use({
    install(Vue, options) {
        Vue.prototype.GLOBAL = GLOBAL;
    }
});

const _axios = require("axios");

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios = _axios.create({
        baseURL: window.location.origin + "/api/v1/",
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-TOKEN": token.content
        }
    });
    window.axios.interceptors.request.use(
        function(config) {
            if (config.params && config.params.sortDesc) {
                let params = _.clone(config.params);
                params.sortDesc = params.sortDesc.map(e => {
                    return e ? "asc" : "desc";
                });
                config.params = params;
            }
            config.headers.Authorization =
                "Bearer " + Vue.cookie.get("apiToken");
            return config;
        },
        function(error) {
            if (401 === error.response.status) {
                // window.location = '/home';
            } else {
                return Promise.reject(error);
            }
        }
    );
} else {
    console.error(
        "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
    );
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from "laravel-echo";

window.Pusher = require("pusher-js");

window.Echo = new Echo({
    broadcaster: "pusher",
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    wsHost: process.env.MIX_PUSHER_APP_HOST,
    wsPort: process.env.MIX_PUSHER_APP_PORT,
    wssPort: process.env.MIX_PUSHER_APP_PORT,
    disableStats: true,
    encrypted: process.env.MIX_PUSHER_APP_PORT == "true"
});

const ignoreWarnMessage =
    "The .native modifier for v-on is only valid on components but it was used on <div>.";
Vue.config.warnHandler = function(msg, vm, trace) {
    // `trace` is the component hierarchy trace
    if (msg === ignoreWarnMessage) {
        msg = null;
        vm = null;
        trace = null;
    }
};
