require("./bootstrap");

import Vue from "vue";
import LandingView from "@/pages/landing";
import Vuetify from "vuetify/lib";
import VeeValidate from "vee-validate";
import ToastSnackBar from "@/components/toast-snackbar";
import VueLocalStorage from "vue-ls";

import vuetify from "@/js/plugin/vuetify";

Vue.use(VueLocalStorage);
Vue.use(ToastSnackBar);

Vue.use(VeeValidate);
Vue.use(Vuetify, {
    iconfont: "mdi"
});

window.app = new Vue({
    vuetify,
    render: h => h(LandingView)
}).$mount("#app");
