var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { light: "", id: "vue-app" } },
    [
      _c(
        "v-app-bar",
        {
          class: _vm.scrollVisible ? "primary" : "transparent",
          staticStyle: { transition: "all .5s ease-in-out" },
          attrs: { dark: "", fixed: "", flat: !_vm.scrollVisible, dense: "" }
        },
        [
          _c("v-toolbar-title", [_vm._v("TeraWORK™")]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { small: "", dark: "", outlined: "" },
              on: {
                click: function($event) {
                  _vm.showLogin = true
                }
              }
            },
            [_vm._v("Sign in")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-content",
        [
          _c(
            "section",
            [
              _c(
                "v-parallax",
                {
                  staticClass: "parallax-header",
                  style:
                    (_vm.pricing ? "height : 100vh" : "") +
                    ";background :linear-gradient(90deg, #f68946, #f69753)",
                  attrs: { src: "/img/landing/payroll-desk.png", height: "600" }
                },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "white--text",
                      attrs: {
                        column: "",
                        "align-center": "",
                        "justify-center": ""
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "mb-4",
                        attrs: {
                          src: "/storage/logos/" + _vm.hostName + ".logo",
                          width: "300"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "h1",
                        {
                          staticClass:
                            "white--text mb-2 display-1 text-xs-center"
                        },
                        [_vm._v(_vm._s(_vm.siteConfig.business_name))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "subheading mb-3 text-xs-center" },
                        [_vm._v("Powered by Teratomo")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { large: "", color: "blue", dark: "" },
                          on: { click: _vm.startOrLogin }
                        },
                        [_vm._v("Get Started")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "section",
            [
              _c(
                "v-container",
                { attrs: { "grid-list-xl": "" } },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "my-5",
                      attrs: { row: "", wrap: "", "justify-center": "" }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-0 transparent" },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass: "layout justify-center",
                                  attrs: { "primary-title": "" }
                                },
                                [
                                  _c("div", { staticClass: "headline" }, [
                                    _vm._v("Company info")
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-card-text", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.siteConfig.company_description) +
                                    "\n              "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "offset-sm1": "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-0 transparent" },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass: "layout justify-center",
                                  attrs: { "primary-title": "" }
                                },
                                [
                                  _c("div", { staticClass: "headline" }, [
                                    _vm._v("Contact Us")
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list",
                                { staticClass: "transparent" },
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "blue--text text--lighten-2"
                                            },
                                            [_vm._v("phone")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.siteConfig.contact_no
                                                ) +
                                                "\n                    "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "blue--text text--lighten-2"
                                            },
                                            [_vm._v("place")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-content", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.siteConfig.address) +
                                            "\n                  "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "blue--text text--lighten-2"
                                            },
                                            [_vm._v("email")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(_vm.siteConfig.email) +
                                                "\n                    "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-footer",
            { staticClass: "primary" },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "align-center": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "div",
                      { staticClass: "white--text ml-3" },
                      [
                        _vm._v("\n            Made with\n            "),
                        _c("v-icon", { staticClass: "red--text" }, [
                          _vm._v("favorite")
                        ]),
                        _vm._v("by\n            "),
                        _c(
                          "a",
                          {
                            staticClass: "white--text",
                            attrs: {
                              href: "https://www.teratomo.com",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Kaizen Team")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          attrs: { temporary: "", app: "", right: "", width: "500" },
          model: {
            value: _vm.showLogin,
            callback: function($$v) {
              _vm.showLogin = $$v
            },
            expression: "showLogin"
          }
        },
        [
          _c("core-login-drawer", {
            on: {
              close: function($event) {
                _vm.showLogin = false
              }
            },
            model: {
              value: _vm.showLogin,
              callback: function($$v) {
                _vm.showLogin = $$v
              },
              expression: "showLogin"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }